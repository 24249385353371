<template>
  <div class="flex-grow-1 d-flex align-items-center justify-content-center">
    <div class="login-container" v-if="!sended && !error">
      <h5 class="text-gray-100 text-center mb-3">Set new password</h5>
      <b-form class="mb-2 invalid" @submit.prevent="setNewPassword()">
        <tp-icon-input :class="{'invalid' : password_error !== null}" icon="key" v-model="new_password" required class="mb-3" type="password" placeholder="New password" />

        <tp-icon-input :class="{'invalid' : password_error !== null}" icon="key" v-model="repeat_password" required class="mb-3" type="password" placeholder="Repeat password" />

        <p class="small text-danger" v-if="password_error !== null">
          {{password_error}}
        </p>

        <b-btn :disabled="loading" class="w-100 rounded-pill button-gradient-primary border-0 mb-3" type="submit">
          <span v-if="loading">
            <b-spinner style="height: 16px; width: 16px;"  label="Spinning" />
            Loading
          </span>

          <span v-if="!loading">Set</span>
        </b-btn>

        <b-link class="link-gray-300" to="/login">
          <b-icon icon="chevron-left" /> Go to login
        </b-link>
      </b-form>
    </div>

    <div v-else-if="error" class="d-flex flex-column align-items-center">
      <img src="@/assets/illustrations/error_illustration.svg" width="400" class="mb-5" alt="">

      <p class="h4 mb-4">
        Something went <span class="text-danger">wrong!</span>
      </p>

      <b-btn variant="primary" @click="sended = false; error = false" class="button-gradient-primary mb-3">
        Retry
      </b-btn>

      <b-link class="link-gray-300" to="/login">
        <b-icon icon="chevron-left" /> Go to login
      </b-link>
    </div>

    <div v-else class="d-flex flex-column align-items-center">
      <img src="@/assets/illustrations/success_illustration.svg" width="400" class="mb-5" alt="">

      <p class="h4 mb-4">
        Password updated <span class="text-success"> successfully! </span>
      </p>

      <router-link class="btn button-gradient-primary" to="/login">
        Go to login
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "reset_password",
  data: () => ({
    email: '',
    error: false,
    loading: false,
    sended: false,
    message: '',
    new_password: '',
    repeat_password: null,
    password_error: '',
    query_token: '',
  }),

  methods: {
    setNewPassword() {
      if(this.repeat_password === this.new_password) {
        this.password_error = null
        this.loading = true

        this.axios.post('reset-password', {
          token: this.query_token,
          new_password: this.new_password,
        })
            .then(response => {
              this.loading = false
              this.message = response.data
              this.sended = true
            })
            .catch(error => {
              this.new_password = ''
              this.repeat_password = ''
              this.loading = false
              this.error = true
            })
      } else {
        this.password_error = 'Password mismatch!'
      }
    },
  },

  mounted() {
    this.query_token = this.$route.query.token
  },
}
</script>

<style scoped>

</style>